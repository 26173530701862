<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"></Toolbar>
    <TenantGroupForm :errors="violations" :values="item" ref="createForm"/>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {createHelpers} from 'vuex-map-fields';
import TenantGroupForm from '../../components/tenantGroup/Form';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import CreateMixin from '../../mixins/CreateMixin';

const servicePrefix = 'TenantGroup';

const {mapFields} = createHelpers({
  getterType: 'tenantGroup/getField',
  mutationType: 'tenantGroup/updateField'
});

export default {
  name: 'TenantGroupCreate',
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    Toolbar,
    TenantGroupForm
  },
  data() {
    return {
      item: {}
    };
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },
  methods: {
    ...mapActions('tenantGroup', ['create', 'reset'])
  }
};
</script>
